<!-- 创建试卷 -->
<template>
  <div style="height: 100%; background-color: #fff">
    <el-scrollbar style="height: 100%">
      <el-card style="height: 100%; border: none" shadow="never">
        <el-button
          style="margin-bottom: 10px"
          @click="go_out"
          class="el-icon-back"
          >返回</el-button
        >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="年级：" prop="gradeValue">
            <el-select
              v-model="ruleForm.gradeValue"
              placeholder="请选择年级"
              size="mini"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科：" prop="subjectValue">
            <el-select
              v-model="ruleForm.subjectValue"
              placeholder="请选择年级"
              size="mini"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="试卷名称：" prop="name">
            <el-input v-model="ruleForm.name" size="mini"></el-input>
          </el-form-item>
          <el-form-item
            :label="`标题${index + 1}`"
            v-for="(item, index) in examination_paper_list"
            :key="index"
            class="form_item"
          >
            <el-input
              v-model="item.headlineName"
              size="mini"
              style="width: 85%"
            ></el-input>
            <span>
              <el-button type="text" @click="subjectOperation(0, item, index)"
                >添加题目</el-button
              >
              <el-button type="text" @click="subjectOperation(1, item)"
                >删除</el-button
              >
            </span>
            <el-card shadow="always" style="margin-top: 15px; height: 250px">
              <el-main style="height: 250px">
                <ul>
                  <li
                    v-for="(li_item, li_index) in item.children"
                    :key="li_index"
                    style="display: flex"
                  >
                    <span style="display: inline-block; width: 70px">
                      {{ `标题${li_index + 1}:` }}
                    </span>
                    <div style="display: inline-block">
                      <p v-html="li_item.topicName"></p>
                      <span
                        v-for="(
                          span_item, span_index
                        ) in li_item.examTopicAnswerEntityList"
                        :key="span_index"
                      >
                        <span style="display: flex">
                          <span
                            v-html="span_item.serial"
                            style="width: 30px"
                          ></span>
                          <span v-html="span_item.content"></span>
                        </span>
                      </span>
                    </div>
                    <span>
                      <el-button
                        type="text"
                        @click="subjectOperation(2, li_item, index, li_index)"
                        >删除</el-button
                      >
                    </span>
                  </li>
                </ul>
              </el-main>
            </el-card>
          </el-form-item>
          <el-form-item label="考试时长(分)：">
            <el-input-number
              v-model="ruleForm.num"
              :min="1"
              :max="300"
              label="描述文字"
              size="mini"
            ></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="testOperation(0, 'ruleForm')"
              size="mini"
              >提交</el-button
            >
            <el-button @click="testOperation(1, 'ruleForm')" size="mini"
              >重置</el-button
            >
            <el-button type="success" @click="testOperation(2)" size="mini"
              >添加标题</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </el-scrollbar>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <span>
        ID:
        <el-input
          v-model="subjectID"
          size="mini"
          style="width: 20%; margin-right: 10px"
        ></el-input
        >题型:
        <el-select
          v-model="subjectTypeValue"
          placeholder="请选择题目类型"
          size="mini"
          style="width: 20%; margin-right: 10px"
          @change="(val) => inquireTopic()"
        >
          <el-option
            v-for="item in subjectType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="primary" size="mini" @click="inquireTopic"
          >查询</el-button
        >
      </span>
      <el-table
        :data="tableData"
        border
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column prop="id" label="id" width="80"></el-table-column>
        <el-table-column label="年级" width="80" align="center">
          <template slot-scope="{ row }">{{ row.gradeName }}</template>
        </el-table-column>
        <el-table-column label="学科" width="80" align="center">
          <template slot-scope="{ row }">{{ row.subjectName }}</template>
        </el-table-column>
        <el-table-column prop="type" label="题型" width="80"></el-table-column>
        <el-table-column
          label="题目名称"
          width="600"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="{ row }">
            <div v-html="row.topicName"></div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page"
        :page-size="10"
        :total="total"
        @current-change="changePaging"
        class="pager"
      ></el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ClickDialogVisible">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  examUpdate,
  examAdd,
  examTopicAll,
  examMessage,
} from "../../../api/api";
export default {
  data() {
    return {
      page: 1,
      total: 20,
      ruleForm: {
        //参数
        gradeValue: "", //选中的年级
        subjectValue: "", //选中的学科
        name: "", //试卷名称
        num: "", //建议时长
      },
      rules: {
        //规则
        gradeValue: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        subjectValue: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入试卷名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      gradeOptions: this.parameter.grade(),
      subjectOptions: this.parameter.subject(),
      examination_paper_list: [], //题目数据
      dialogVisible: false, //新建题目显示
      subjectID: "", //添加题目的ID
      tableData: [], //弹框表格数据
      subjectTypeValue: 2, //题目类型
      subjectType: this.parameter.variantSubjectData(),
      multipleSelection: [], //多选的数据
      index: null, //编辑下标
      editId: "add",
      edit_arr: [], //创建试卷选中的题目id
    };
  },

  components: {},
  mounted() {
    this.skip();
  },
  methods: {
    // 禁选表格
    selectable(row, index) {
      let show = true;
      let item = null;
      if (this.edit_arr.length > 0) {
        item = this.edit_arr.find((item) => {
          return item == row.id;
        });
        if (item) {
          show = false;
        }
      }
      return show;
    },
    skip() {
      if (this.$route.query.id == "add") {
        this.editId = this.$route.query.id;
      } else {
        this.editId = this.$route.query.id;
        examMessage({ id: this.editId }).then((res) => {
          this.ruleForm.gradeValue = res.data[0].gradeLevel;
          this.ruleForm.subjectValue = res.data[0].subjectId;
          this.ruleForm.name = res.data[0].name;
          this.ruleForm.num = res.data[0].suggestTime;
          if (res.data[0].headlineMessage == null) {
            this.examination_paper_list = [];
          } else {
            this.examination_paper_list = res.data[0].headlineMessage;
          }
          this.examination_paper_list.map((item, index) => {
            item["children"] = [];
            item["topicMessage"].map((ite) => {
              var obj = ite[0];
              obj["examTopicAnswerEntityList"] = ite[1][0];
              item["children"].push(obj);
            });
          });
        });
      }
    },
    // 返回
    go_out() {
      this.$router.push({ path: "/materialGather/PaperTest" });
    },
    init(params) {
      examTopicAll(params).then((res) => {
        this.total = res.data.total;
        res.data.list.map((item) => {
          var grade = this.gradeOptions.find((fin) => {
            return fin.value == item.grade;
          });
          item["gradeName"] = grade.label;
          var subject = this.subjectOptions.find((fin) => {
            return fin.value == item.subject;
          });
          item["subjectName"] = subject.label;

          var subjectType = this.subjectType.find((fin) => {
            return fin.value == item.topicType;
          });
          item["type"] = subjectType.label;
        });
        this.tableData = res.data.list;
      });
    },
    // 题目操作按钮
    subjectOperation(type, val, index, li_index) {
      // 0 添加  1 删除
      if (type == 0) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.edit_arr = [];
            let arr = [];
            this.examination_paper_list.forEach((item) => {
              item.children.forEach((child_item) => {
                arr.push(child_item.id);
              });
            });
            this.edit_arr = arr;
            this.inquireTopic();
            this.dialogVisible = true;
            this.multipleSelection = [];
            this.index = index;
          } else {
            return false;
          }
        });
      } else if (type == 1) {
        this.$confirm("此操作将删除该题目, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.examination_paper_list = this.examination_paper_list.filter(
              (item) => {
                return item.id !== val.id;
              }
            );
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {});
      } else if (type == 2) {
        this.$confirm("此操作将删除该题目, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = this.examination_paper_list[index].children.filter(
              (item) => {
                return item.id != val.id;
              }
            );
            this.examination_paper_list[index].children = data;
            this.$forceUpdate();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch(() => {});
      }
    },
    // 试卷操作按钮
    testOperation(type, formName) {
      // 0 提交  1 重置  2添加
      if (type == 0) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm("此操作将永久保存, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                var headlineMessage = [];
                var num = 0;
                this.examination_paper_list.map((item) => {
                  var subjectId = [];
                  item.children.map((ite) => {
                    subjectId.push(ite.id);
                  });
                  item["topicId"] = subjectId.toString();
                  headlineMessage.push({
                    topicId: item["topicId"],
                    headlineName: item.headlineName,
                  });
                });
                headlineMessage.map((item, index) => {
                  if (item.headlineName == "") {
                    num++;
                  }
                  if (item.topicId.length<=0 ) {
                    num++;
                  }
                });
                if (num > 0) {
                  this.$message.error("题目标题或内容不可为空");
                  return;
                }
                if (this.$route.query.id == "add") {
                  examAdd({
                    name: this.ruleForm.name,
                    subjectId: this.ruleForm.subjectValue,
                    gradeLevel: this.ruleForm.gradeValue,
                    suggestTime: this.ruleForm.num,
                    headlineMessage: headlineMessage,
                  }).then((res) => {
                    if (res.message == "请求成功") {
                      this.$store.commit("setBrowserRouter", false);
                      this.$message({
                        type: "success",
                        message: "保存成功,1秒后自动返回...",
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "PaperTest", params: {} });
                      }, 1000);
                    } else {
                      this.$message.error(res.message);
                    }
                  });
                } else {
                  //编辑
                  // id
                  examUpdate({
                    id: this.editId,
                    name: this.ruleForm.name,
                    subjectId: this.ruleForm.subjectValue,
                    gradeLevel: this.ruleForm.gradeValue,
                    suggestTime: this.ruleForm.num,
                    headlineMessage: headlineMessage,
                  }).then((res) => {
                    if (res.message == "请求成功") {
                      this.$store.commit("setBrowserRouter", false);
                      this.$message({
                        type: "success",
                        message: "保存成功,1秒后自动返回...",
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "PaperTest", params: {} });
                      }, 1000);
                    } else {
                      this.$message.error(res.message);
                    }
                  });
                }
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消提交",
                });
              });
          } else {
            return false;
          }
        });
      } else if (type == 1) {
        this.$refs[formName].resetFields();
        this.skip();
      } else if (type == 2) {
        this.examination_paper_list.push({
          id: this.examination_paper_list.length + 1,
          headlineName: "",
          children: [],
        });
      }
    },
    // 多选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 二次关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //查询题目
    inquireTopic() {
      if (this.subjectID == "" || this.subjectID == null) {
        this.init({
          pageNum: this.page,
          pageSize: 10,
          subject: this.ruleForm.subjectValue,
          grade: this.ruleForm.gradeValue,
          topicType: this.subjectTypeValue,
        });
      } else {
        this.subjectTypeValue = null;
        this.init({
          id: this.subjectID,
          subject: this.ruleForm.subjectValue,
          grade: this.ruleForm.gradeValue,
        });
      }
    },
    //弹窗确认
    ClickDialogVisible() {
      this.dialogVisible = false;
      this.examination_paper_list[this.index].children.push(
        ...this.multipleSelection
      );
    },
    //分页
    changePaging(val) {
      this.page = val;
      this.inquireTopic();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../style/mouse.less";
.form_item {
  /deep/.el-form-item__content {
    // display: flex;
  }
  span {
    width: 200px;
    padding-left: 20px;
  }
}
</style>
